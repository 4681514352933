function initialize() {
    var mapOptions = {
        center : new google.maps.LatLng( 48.16837392194981, 11.644306209192665 ),
        zoom : 16,
        mapTypeId : google.maps.MapTypeId.ROADMAP
    };

    var map = new google.maps.Map( document.getElementById( "map_canvas" ), mapOptions );

    var marker = new google.maps.Marker( {
        position : new google.maps.LatLng( 48.16828089995864, 11.644095655784996 ),
        map : map,
        title : "Hausärzte Johanneskirchen - Internistische Gemeinschaftspraxis"
    } );
}

$( document ).ready( function () {

    var pathURI = window.location.pathname;
    $( 'a.navLinks[href="' + pathURI + '"' ).parent().addClass( 'active' );

    $( '[data-lang-toggle]' ).click( function ( e ) {
        e.preventDefault();

        var lang = $( this ).data( 'lang-toggle' );
        var path = '';

        if(lang == 'en' && pathURI.indexOf('/'+lang) == -1) {
            path = '/en'+pathURI;
        }
        
        if(lang == 'de' && pathURI.indexOf('/en') == 0) {
            if(pathURI == '/en' || pathURI == '/en/') {
                path = '/';
            } else {
                path = pathURI.replace('/en', '');
            }

        }

        location.href = path;
    } );

} );